<template>
	<page-header-wrapper>
		<template slot="extra">
		    <a-button type="primary" @click="add" v-action:add>新增</a-button>
		</template>
		<a-card :bordered="false">
			<div class="table-page-search-wrapper">
				<a-form layout="inline">
					<a-row :gutter="6">
						<a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" :xxl="6" v-action:query>
							<a-form-item label="角色名称">
								<a-input placeholder="请输入" v-model="queryParam.key" style="width:160px" />
							</a-form-item>
						</a-col>
						<a-col :xs="24" :sm="24" :md="8" :lg="9" :xl="6" :xxl="6" v-action:query>
							<a-form-item label="状态">
								<a-select placeholder="请选择" v-model="queryParam.status" style="width:160px">
									<a-select-option value="99">全部</a-select-option>
									<a-select-option value="0">启用</a-select-option>
									<a-select-option value="1">禁用</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
						<a-col :xs="12" :sm="12" :md="5" :lg="4" :xl="6" xxl="6" v-action:query>
							<span class="table-page-search-submitButtons">
								<a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
								<a-button style="margin-left: 8px" @click="reset">重置</a-button>
							</span>
						</a-col>
					</a-row>
				</a-form>
			</div>

			<s-table ref="table" size="default" :columns="columns" :data="loadData" :rowKey="(record) => record.id" :pageNum="pageNum">
				<span slot="serial" slot-scope="text, record, index">
					{{ index + 1 }}
				</span>
				<span slot="state_role" slot-scope="status,record">
					<a-tag color="#87d068" v-if="status==0">启用</a-tag>
					<a-tag color="#909399" v-else-if="status==1">禁用</a-tag>
				</span>
				<span slot="creator" slot-scope="creator">
					--
				</span>
				<span slot="createTime" slot-scope="create_time">
					{{create_time|time}}
				</span>
				<span slot="action" slot-scope="text, record">
					<a @click="editRole(record)" v-action:edit>编辑</a>
					<a-divider type="vertical" />
					<a href="javascript:;" @click="deleteRole(record)" v-action:del>删除</a>
					<a-divider type="vertical" />
					<a v-action:setStatus v-if="record.status==0" @click="setStatus(record.id,1)">禁用</a>
					<a v-action:setStatus v-else-if="record.status==1" @click="setStatus(record.id,2)">启用</a>
					<a-divider type="vertical" />
					<a href="javascript:;" @click="goDetail(record)" v-action:setAuth>分配权限</a>
				</span>
			</s-table>
			<!-- 弹窗 -->
			<a-modal
				:title="id?'编辑角色':'添加角色'"
				:visible="visible"
				:confirmLoading="confirmLoading"
				width="535px" class="modal_foot_none" :closable="false">
				<div>
					<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" @submit="handleSubmit">
						<a-form-item label="角色名称">
							<a-input v-decorator="['name', { rules: [{ required: true, message: '请输入角色名称!' }] }]" placeholder="请输入角色名称" />
						</a-form-item>
						<a-form-item :wrapper-col="{ span: 24 }" style="text-align: center;">
							<a-button type="primary" html-type="submit">
								确定
							</a-button>
							<a-button type="default" style="margin-left: 10px;" @click="resetForm">
								取消
							</a-button>
						</a-form-item>
					</a-form>
				</div>
			</a-modal>
		</a-card>
	</page-header-wrapper>
</template>

<script>
	import {
		STable
	} from '@/components'
	import moment from 'moment'
	import { getUrlKey } from '@/utils'
	import RoleModal from './modules/RoleModal'
	import {
		roleList,
		delRole,
		addRole,
		editRole,
		setRoleStatus
	} from '@/api/system'
	export default {
		name: 'TableList',
		components: {
			STable,
			RoleModal
		},
		data() {
			return {
				description: '',
				visible: false,
				confirmLoading:false,
				mdl: {},
				// 高级搜索 展开/关闭
				advanced: false,
				// 查询参数
				queryParam: {
					key:'',
					status:'99'
				},
				// 表头
				columns: [
					{
						title: '序号',
						scopedSlots: {
							customRender: 'serial'
						},
						align:'center',
						width:'60px'
					},
					{
						title: '状态',
						dataIndex:'status',
						scopedSlots: {
							customRender: 'state_role'
						},
						align:'center'
					},
					{
						title: '名称',
						dataIndex: 'name',
						align:'center'
					},
					// {
					// 	title: '描述',
					// 	dataIndex: 'status'
					// },
					{
						title: '创建人',
						dataIndex: 'creator',
						scopedSlots: {
							customRender: 'creator'
						},
						align:'center',
						width:'100px'
					},
					{
						title: '创建时间',
						dataIndex: 'create_time',
						scopedSlots: {
							customRender: 'createTime'
						},
						align:'center'
					}, 
					{
						title: '操作',
						width: '240px',
						dataIndex: 'action',
						scopedSlots: {
							customRender: 'action'
						},
						align:'center'
					}
				],
				// 加载数据方法 必须为 Promise 对象
				loadData: parameter => {
					const requestParameters = Object.assign({}, parameter, this.queryParam)
					if(requestParameters.status==99){
						requestParameters.status=''
					}
					return roleList(requestParameters)
						.then(res => {
							return res.data
						})
				},
				selectedRowKeys: [],
				selectedRows: [],
				form:this.$form.createForm(this),
				id:'',   //角色id
				pageNum:1
			}
		},
		filters: {
			time(t) {
				if (t) {
					return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
				} else {
					return t
				}
			}
		},
		created(){
			this.pageNum = Number(getUrlKey('p')||1)
		},
		methods: {
			add(){
				this.visible = true
			},
			goDetail(data){
				this.pageNum = Number(getUrlKey('p'))||1
				this.$router.push({path:'/system/roleEdit?rid='+data.id})
			},
			setStatus(id,type){   //禁用恢复
				let params = {
					id:id,
					type:type
				}
				setRoleStatus(params).then(res =>{
					if(res.code==200){
						this.$message.success(res.msg)
						this.$refs.table.refresh(true)
					}else{
						this.$message.error(res.msg)
					}
				}).catch(res =>{
					
				})
			},
			deleteRole(data){   //删除角色
				let that = this
				this.$confirm({
					content:'确认删除吗?',
					onCancel(){
						
					},
					onOk(){
						let params = {
							id:data.id
						}
						delRole(params).then(res =>{
							if(res.code==200){
								that.$message.success(res.msg)
								that.$refs.table.refresh(true)
							}else{
								that.$message.error(res.msg)
							}
						}).catch(res =>{
							
						})
					}
				})
			},
			editRole(data){
				this.id = data.id
				this.$nextTick(()=>{
				    this.form.setFieldsValue({
				        "name": data.name
				    })
				})
				this.visible = true
			},
			handleSubmit(e) {
			    e.preventDefault();
			    let that = this
			    this.form.validateFields((err, values) => {
			        if (!err) {
						this.confirmLoading = true
			            let params = {
			                id:that.id,
			                name:values.name
			            }
						if(that.id){
							editRole(params).then(res =>{
							    if(res.code==200){
							        that.$message.success('编辑成功')
							        that.visible = false
							        that.$refs.table.refresh(true)
							        that.resetForm()
									that.confirmLoading = false
							    }else{
							        that.$message.success(res.msg) 
									that.confirmLoading = false
							        setTimeout(_=>{
							            that.visible = false
							        },600)
							    }
							})
						}else{
							addRole(params).then(res =>{
							    if(res.code==200){
							        that.$message.success('新增成功')
							        that.visible = false
							        that.$refs.table.refresh(true)
							        that.resetForm()
									that.confirmLoading = false
							    }else{
							        that.$message.success(res.msg) 
									that.confirmLoading = false
									that.resetForm()
							        setTimeout(_=>{
							            that.visible = false
							        },600)
							    }
							})
						}
			        }else{
			
			        }
			    });
			},
			resetForm(){
				this.visible = false
				this.id = ''
				this.$nextTick(()=>{
				    this.form.setFieldsValue({
				        "name": ''
				    })
				})
			},
			reset(){
				this.queryParam.status='99'
				this.queryParam.key = ''
				this.$refs.table.refresh(true)
			}
		}
	}
	
	
</script>
