import request from '@/utils/request'

const isProd = process.env.NODE_ENV === 'production'


const sysApi = {
  roleList: (isProd?'':'/api')+'/newAdmin.php?c=User&a=group_list',
  delRole: (isProd?'':'/api')+'/newAdmin.php?c=User&a=del_group',
  addRole: (isProd?'':'/api')+'/newAdmin.php?c=User&a=add_group',
  editRole: (isProd?'':'/api')+'/newAdmin.php?c=User&a=edit_group',
  setRoleStatus: (isProd?'':'/api')+'/newAdmin.php?c=User&a=forbidden_group',
  
  
  allMenuList: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=menu_list',
  addMenu: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=add_module',
  editMenu: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=edit_module',
  delMenu: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=del_module',
  allMenuLists: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=all_module',
  
  menuList: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=module_list',
  bindAuth: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=bind_module',
  getNodes: (isProd?'':'/api')+'/newAdmin.php?c=Sys&a=selected_module',
  
  allRoleList: (isProd?'':'/api')+'/newAdmin.php?c=User&a=all_group_list',
  addUser: (isProd?'':'/api')+'/newAdmin.php?c=User&a=add_sys_user',
  editUser: (isProd?'':'/api')+'/newAdmin.php?c=User&a=edit_sys_user',
  getUserList: (isProd?'':'/api')+'/newAdmin.php?c=User&a=sys_user_list',
  delUser: (isProd?'':'/api')+'/newAdmin.php?c=User&a=del_sys_user',
  setUserStatus: (isProd?'':'/api')+'/newAdmin.php?c=User&a=forbidden_sys_user',
	
  analysis: (isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=statisticAnalysis',
	
  getNotice: (isProd?'':'/api')+'/newAdmin.php?c=Jmh&a=waitbusiness',
	
}

/*提醒*/
export function getNotice (parameter) {
  return request({
    url: sysApi.getNotice,
    method: 'post',
    data: parameter
  })
}


/*角色列表*/
export function roleList (parameter) {
  return request({
    url: sysApi.roleList,
    method: 'post',
    data: parameter
  })
}

/*删除角色*/
export function delRole (parameter) {
  return request({
    url: sysApi.delRole,
    method: 'post',
    data: parameter
  })
}

/*新增角色*/
export function addRole (parameter) {
  return request({
    url: sysApi.addRole,
    method: 'post',
    data: parameter
  })
}
/*编辑角色*/
export function editRole (parameter) {
  return request({
    url: sysApi.editRole,
    method: 'post',
    data: parameter
  })
}

/*修改角色状态*/
export function setRoleStatus (parameter) {
  return request({
    url: sysApi.setRoleStatus,
    method: 'post',
    data: parameter
  })
}



/*菜单列表(下拉)*/
export function allMenuList (parameter) {
  return request({
    url: sysApi.allMenuList,
    method: 'post',
    data: parameter
  })
}
/*菜单新增*/
export function addMenu (parameter) {
  return request({
    url: sysApi.addMenu,
    method: 'post',
    data: parameter
  })
}

/*菜单编辑*/
export function editMenu (parameter) {
  return request({
    url: sysApi.editMenu,
    method: 'post',
    data: parameter
  })
}

/*删除菜单*/
export function delMenu (parameter) {
  return request({
    url: sysApi.delMenu,
    method: 'post',
    data: parameter
  })
}


/*菜单列表*/ 
export function menuList (parameter) {
  return request({
    url: sysApi.menuList,
    method: 'post',
    data: parameter
  })
}


/*绑定权限*/ 
export function bindAuth(parameter) {
  return request({
    url: sysApi.bindAuth,
    method: 'post',
    data: parameter
  })
}


/*获取选中节点*/ 
export function getNodes(parameter) {
  return request({
    url: sysApi.getNodes,
    method: 'post',
    data: parameter
  })
}


/*菜单列表(带按钮权限)*/ 
export function allMenuLists(parameter) {
  return request({
    url: sysApi.allMenuLists,
    method: 'post',
    data: parameter
  })
}


//角色列表不分页 
export function allRoleList(parameter) {
  return request({
    url: sysApi.allRoleList,
    method: 'post',
    data: parameter
  })
}

//添加用户
export function addUser(parameter) {
  return request({
    url: sysApi.addUser,
    method: 'post',
    data: parameter
  })
}

//编辑用户
export function editUser(parameter) {
  return request({
    url: sysApi.editUser,
    method: 'post',
    data: parameter
  })
}

//用户列表 
export function getUserList(parameter) {
  return request({
    url: sysApi.getUserList,
    method: 'post',
    data: parameter
  })
}

//删除用户 
export function delUser(parameter) {
  return request({
    url: sysApi.delUser,
    method: 'post',
    data: parameter
  })
}

//禁用用户 
export function setUserStatus(parameter) {
  return request({
    url: sysApi.setUserStatus,
    method: 'post',
    data: parameter
  })
}

//统计
export function analysis(parameter) {
  return request({
    url: sysApi.analysis,
    method: 'post',
    data: parameter
  })
}